/* chracters for SageUIIcons */

$sg-icon-add: "\e900";
$sg-icon-alert: "\e901";
$sg-icon-angle-double-left: "\e902";
$sg-icon-angle-double-right: "\e903";
$sg-icon-angle-down: "\e904";
$sg-icon-angle-left: "\e905";
$sg-icon-angle-right: "\e906";
$sg-icon-angle-up: "\e907";
$sg-icon-arrow-down: "\e908";
$sg-icon-arrow-left: "\e909";
$sg-icon-arrow-right: "\e90a";
$sg-icon-arrow-up: "\e90b";
$sg-icon-attach: "\e90c";
$sg-icon-bars: "\e90d";
$sg-icon-blocked-square: "\e90e";
$sg-icon-blocked: "\e90f";
$sg-icon-calendar: "\e910";
$sg-icon-camera: "\e911";
$sg-icon-card-view: "\e912";
$sg-icon-caret-left: "\e913";
$sg-icon-caret-right: "\e914";
$sg-icon-carrier: "\e915";
$sg-icon-cart: "\e916";
$sg-icon-chart-bar: "\e917";
$sg-icon-chart-line: "\e918";
$sg-icon-chart-pie: "\e919";
$sg-icon-check-square-unselected: "\e91a";
$sg-icon-check-square: "\e91b";
$sg-icon-check: "\e91c";
$sg-icon-chevron-down: "\e91d";
$sg-icon-chevron-left: "\e91e";
$sg-icon-chevron-right: "\e91f";
$sg-icon-chevron-up: "\e920";
$sg-icon-clock: "\e921";
$sg-icon-close: "\e922";
$sg-icon-cog: "\e923";
$sg-icon-comments: "\e924";
$sg-icon-copy: "\e925";
$sg-icon-credit-card: "\e926";
$sg-icon-csv: "\e927";
$sg-icon-delete: "\e928";
$sg-icon-disconnect: "\e929";
$sg-icon-download: "\e92a";
$sg-icon-draft: "\e92b";
$sg-icon-drag-vertical: "\e92c";
$sg-icon-drag: "\e92d";
$sg-icon-dropdown-up: "\e92e";
$sg-icon-dropdown: "\e92f";
$sg-icon-duplicate: "\e930";
$sg-icon-edit: "\e931";
$sg-icon-exclamation-circle: "\e932";
$sg-icon-external-link: "\e933";
$sg-icon-facebook: "\e934";
$sg-icon-favourite-lined: "\e935";
$sg-icon-favourite: "\e936";
$sg-icon-fax: "\e937";
$sg-icon-feedback: "\e938";
$sg-icon-file-o: "\e939";
$sg-icon-file-text-o: "\e93a";
$sg-icon-film: "\e93b";
$sg-icon-filter: "\e93c";
$sg-icon-folder: "\e93d";
$sg-icon-gift: "\e93e";
$sg-icon-google-plus: "\e93f";
$sg-icon-help: "\e940";
$sg-icon-home: "\e941";
$sg-icon-image: "\e942";
$sg-icon-in-progress: "\e943";
$sg-icon-info-circle: "\e944";
$sg-icon-instagram: "\e945";
$sg-icon-key: "\e946";
$sg-icon-linkedin: "\e947";
$sg-icon-listing-g: "\e948";
$sg-icon-listing-v: "\e949";
$sg-icon-locked: "\e94a";
$sg-icon-logout: "\e94b";
$sg-icon-marker: "\e94c";
$sg-icon-message: "\e94d";
$sg-icon-minus: "\e94e";
$sg-icon-mobile: "\e94f";
$sg-icon-pdf: "\e950";
$sg-icon-pencil: "\e951";
$sg-icon-people: "\e952";
$sg-icon-person: "\e953";
$sg-icon-phone: "\e954";
$sg-icon-pinterest: "\e955";
$sg-icon-print: "\e956";
$sg-icon-progressed: "\e957";
$sg-icon-question: "\e958";
$sg-icon-redo: "\e959";
$sg-icon-refresh: "\e95a";
$sg-icon-resize-horizontal: "\e95b";
$sg-icon-resize-vertical: "\e95c";
$sg-icon-save: "\e95d";
$sg-icon-screen-share: "\e95e";
$sg-icon-search: "\e95f";
$sg-icon-share-alt: "\e960";
$sg-icon-shop: "\e961";
$sg-icon-sliders: "\e962";
$sg-icon-sort-down: "\e963";
$sg-icon-sort-up: "\e964";
$sg-icon-split: "\e965";
$sg-icon-sync: "\e966";
$sg-icon-talk: "\e967";
$sg-icon-times-circle: "\e968";
$sg-icon-times: "\e969";
$sg-icon-tumblr: "\e96a";
$sg-icon-twitter: "\e96b";
$sg-icon-undo: "\e96c";
$sg-icon-unlocked: "\e96d";
$sg-icon-upload: "\e96e";
$sg-icon-uploaded: "\e96f";
$sg-icon-warning: "\e970";
$sg-icon-xing: "\e971";
$sg-icon-youtube: "\e972";
