@import '../theme/settings/colors';
@import '../theme/settings/icons-characters';


/* do not remove this */
.do-not-remove-rendering-fix {
    content: "for some reason, js appended style have issue firing first 20 lines of code.";
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    top: 0;
    bottom: 0;
    display: none;
    visibility: hidden;
    width: 0;
    height: 0;
    font-size: 0;
    position: fixed;
}

/* sage ui icons custom set */
.sg-icon {
    /* use important to prevent issues with browser extensions that change fonts */
    font-family: 'SageUIIcons' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.sg-icon-add {
    &:before {
        content: $sg-icon-add;
    }
}

.sg-icon-alert {
    &:before {
        content: $sg-icon-alert;
    }
}

.sg-icon-angle-double-left {
    &:before {
        content: $sg-icon-angle-double-left;
    }
}

.sg-icon-angle-double-right {
    &:before {
        content: $sg-icon-angle-double-right;
    }
}

.sg-icon-angle-down {
    &:before {
        content: $sg-icon-angle-down;
    }
}

.sg-icon-angle-left {
    &:before {
        content: $sg-icon-angle-left;
    }
}

.sg-icon-angle-right {
    &:before {
        content: $sg-icon-angle-right;
    }
}

.sg-icon-angle-up {
    &:before {
        content: $sg-icon-angle-up;
    }
}

.sg-icon-arrow-down {
    &:before {
        content: $sg-icon-arrow-down;
    }
}

.sg-icon-arrow-left {
    &:before {
        content: $sg-icon-arrow-left;
    }
}

.sg-icon-arrow-right {
    &:before {
        content: $sg-icon-arrow-right;
    }
}

.sg-icon-arrow-up {
    &:before {
        content: $sg-icon-arrow-up;
    }
}

.sg-icon-attach {
    &:before {
        content: $sg-icon-attach;
    }
}

.sg-icon-bars {
    &:before {
        content: $sg-icon-bars;
    }
}

.sg-icon-blocked-square {
    &:before {
        content: $sg-icon-blocked-square;
    }
}

.sg-icon-blocked {
    &:before {
        content: $sg-icon-blocked;
    }
}

.sg-icon-calendar {
    &:before {
        content: $sg-icon-calendar;
    }
}

.sg-icon-camera {
    &:before {
        content: $sg-icon-camera;
    }
}

.sg-icon-card-view {
    &:before {
        content: $sg-icon-card-view;
    }
}

.sg-icon-caret-left {
    &:before {
        content: $sg-icon-caret-left;
    }
}

.sg-icon-caret-right {
    &:before {
        content: $sg-icon-caret-right;
    }
}

.sg-icon-carrier {
    &:before {
        content: $sg-icon-carrier;
    }
}

.sg-icon-cart {
    &:before {
        content: $sg-icon-cart;
    }
}

.sg-icon-chart-bar {
    &:before {
        content: $sg-icon-chart-bar;
    }
}

.sg-icon-chart-line {
    &:before {
        content: $sg-icon-chart-line;
    }
}

.sg-icon-chart-pie {
    &:before {
        content: $sg-icon-chart-pie;
    }
}

.sg-icon-check-square-unselected {
    &:before {
        content: $sg-icon-check-square-unselected;
    }
}

.sg-icon-check-square {
    &:before {
        content: $sg-icon-check-square;
    }
}

.sg-icon-check {
    &:before {
        content: $sg-icon-check;
    }
}

.sg-icon-chevron-down {
    &:before {
        content: $sg-icon-chevron-down;
    }
}

.sg-icon-chevron-left {
    &:before {
        content: $sg-icon-chevron-left;
    }
}

.sg-icon-chevron-right {
    &:before {
        content: $sg-icon-chevron-right;
    }
}

.sg-icon-chevron-up {
    &:before {
        content: $sg-icon-chevron-up;
    }
}

.sg-icon-clock {
    &:before {
        content: $sg-icon-clock;
    }
}

.sg-icon-close {
    &:before {
        content: $sg-icon-close;
    }
}

.sg-icon-cog {
    &:before {
        content: $sg-icon-cog;
    }
}

.sg-icon-comments {
    &:before {
        content: $sg-icon-comments;
    }
}

.sg-icon-copy {
    &:before {
        content: $sg-icon-copy;
    }
}

.sg-icon-credit-card {
    &:before {
        content: $sg-icon-credit-card;
    }
}

.sg-icon-csv {
    &:before {
        content: $sg-icon-csv;
    }
}

.sg-icon-delete {
    &:before {
        content: $sg-icon-delete;
    }
}

.sg-icon-disconnect {
    &:before {
        content: $sg-icon-disconnect;
    }
}

.sg-icon-download {
    &:before {
        content: $sg-icon-download;
    }
}

.sg-icon-draft {
    &:before {
        content: $sg-icon-draft;
    }
}

.sg-icon-drag-vertical {
    &:before {
        content: $sg-icon-drag-vertical;
    }
}

.sg-icon-drag {
    &:before {
        content: $sg-icon-drag;
    }
}

.sg-icon-dropdown-up {
    &:before {
        content: $sg-icon-dropdown-up;
    }
}

.sg-icon-dropdown {
    &:before {
        content: $sg-icon-dropdown;
    }
}

.sg-icon-duplicate {
    &:before {
        content: $sg-icon-duplicate;
    }
}

.sg-icon-edit {
    &:before {
        content: $sg-icon-edit;
    }
}

.sg-icon-exclamation-circle {
    &:before {
        content: $sg-icon-exclamation-circle;
    }
}

.sg-icon-external-link {
    &:before {
        content: $sg-icon-external-link;
    }
}

.sg-icon-facebook {
    &:before {
        content: $sg-icon-facebook;
    }
}

.sg-icon-favourite-lined {
    &:before {
        content: $sg-icon-favourite-lined;
    }
}

.sg-icon-favourite {
    &:before {
        content: $sg-icon-favourite;
    }
}

.sg-icon-fax {
    &:before {
        content: $sg-icon-fax;
    }
}

.sg-icon-feedback {
    &:before {
        content: $sg-icon-feedback;
    }
}

.sg-icon-file-o {
    &:before {
        content: $sg-icon-file-o;
    }
}

.sg-icon-file-text-o {
    &:before {
        content: $sg-icon-file-text-o;
    }
}

.sg-icon-film {
    &:before {
        content: $sg-icon-film;
    }
}

.sg-icon-filter {
    &:before {
        content: $sg-icon-filter;
    }
}

.sg-icon-folder {
    &:before {
        content: $sg-icon-folder;
    }
}

.sg-icon-gift {
    &:before {
        content: $sg-icon-gift;
    }
}

.sg-icon-google-plus {
    &:before {
        content: $sg-icon-google-plus;
    }
}

.sg-icon-help {
    &:before {
        content: $sg-icon-help;
    }
}

.sg-icon-home {
    &:before {
        content: $sg-icon-home;
    }
}

.sg-icon-image {
    &:before {
        content: $sg-icon-image;
    }
}

.sg-icon-in-progress {
    &:before {
        content: $sg-icon-in-progress;
    }
}

.sg-icon-info-circle {
    &:before {
        content: $sg-icon-info-circle;
    }
}

.sg-icon-instagram {
    &:before {
        content: $sg-icon-instagram;
    }
}

.sg-icon-key {
    &:before {
        content: $sg-icon-key;
    }
}

.sg-icon-linkedin {
    &:before {
        content: $sg-icon-linkedin;
    }
}

.sg-icon-listing-g {
    &:before {
        content: $sg-icon-listing-g;
    }
}

.sg-icon-listing-v {
    &:before {
        content: $sg-icon-listing-v;
    }
}

.sg-icon-locked {
    &:before {
        content: $sg-icon-locked;
    }
}

.sg-icon-logout {
    &:before {
        content: $sg-icon-logout;
    }
}

.sg-icon-marker {
    &:before {
        content: $sg-icon-marker;
    }
}

.sg-icon-message {
    &:before {
        content: $sg-icon-message;
    }
}

.sg-icon-minus {
    &:before {
        content: $sg-icon-minus;
    }
}

.sg-icon-mobile {
    &:before {
        content: $sg-icon-mobile;
    }
}

.sg-icon-pdf {
    &:before {
        content: $sg-icon-pdf;
    }
}

.sg-icon-pencil {
    &:before {
        content: $sg-icon-pencil;
    }
}

.sg-icon-people {
    &:before {
        content: $sg-icon-people;
    }
}

.sg-icon-person {
    &:before {
        content: $sg-icon-person;
    }
}

.sg-icon-phone {
    &:before {
        content: $sg-icon-phone;
    }
}

.sg-icon-pinterest {
    &:before {
        content: $sg-icon-pinterest;
    }
}

.sg-icon-print {
    &:before {
        content: $sg-icon-print;
    }
}

.sg-icon-progressed {
    &:before {
        content: $sg-icon-progressed;
    }
}

.sg-icon-question {
    &:before {
        content: $sg-icon-question;
    }
}

.sg-icon-redo {
    &:before {
        content: $sg-icon-redo;
    }
}

.sg-icon-refresh {
    &:before {
        content: $sg-icon-refresh;
    }
}

.sg-icon-resize-horizontal {
    &:before {
        content: $sg-icon-resize-horizontal;
    }
}

.sg-icon-resize-vertical {
    &:before {
        content: $sg-icon-resize-vertical;
    }
}

.sg-icon-save {
    &:before {
        content: $sg-icon-save;
    }
}

.sg-icon-screen-share {
    &:before {
        content: $sg-icon-screen-share;
    }
}

.sg-icon-search {
    &:before {
        content: $sg-icon-search;
    }
}

.sg-icon-share-alt {
    &:before {
        content: $sg-icon-share-alt;
    }
}

.sg-icon-shop {
    &:before {
        content: $sg-icon-shop;
    }
}

.sg-icon-sliders {
    &:before {
        content: $sg-icon-sliders;
    }
}

.sg-icon-sort-down {
    &:before {
        content: $sg-icon-sort-down;
    }
}

.sg-icon-sort-up {
    &:before {
        content: $sg-icon-sort-up;
    }
}

.sg-icon-split {
    &:before {
        content: $sg-icon-split;
    }
}

.sg-icon-sync {
    &:before {
        content: $sg-icon-sync;
    }
}

.sg-icon-talk {
    &:before {
        content: $sg-icon-talk;
    }
}

.sg-icon-times-circle {
    &:before {
        content: $sg-icon-times-circle;
    }
}

.sg-icon-times {
    &:before {
        content: $sg-icon-times;
    }
}

.sg-icon-tumblr {
    &:before {
        content: $sg-icon-tumblr;
    }
}

.sg-icon-twitter {
    &:before {
        content: $sg-icon-twitter;
    }
}

.sg-icon-undo {
    &:before {
        content: $sg-icon-undo;
    }
}

.sg-icon-unlocked {
    &:before {
        content: $sg-icon-unlocked;
    }
}

.sg-icon-upload {
    &:before {
        content: $sg-icon-upload;
    }
}

.sg-icon-uploaded {
    &:before {
        content: $sg-icon-uploaded;
    }
}

.sg-icon-warning {
    &:before {
        content: $sg-icon-warning;
    }
}

.sg-icon-xing {
    &:before {
        content: $sg-icon-xing;
    }
}

.sg-icon-youtube {
    &:before {
        content: $sg-icon-youtube;
    }
}


body {
    font-family: 'Sage Text', 'Helvetica Neue', Roboto, Arial, Sans-Serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: $text-color;
}

.title__medium, .title__large, .title__xlarge, .title__xxlarge, .tile_xxxlarge, .title_xxxxlarge,
.title_xxxxxlarge, h1, h2, h3, h4, h5, h6 {
    font-family: 'Sage Text', 'Helvetica Neue', Roboto, Arial, Sans-Serif;
    color: $text-color;
}

.title__xxxxxlarge {
    font-size: 32px;
}
/* 
h2, .title__xlarge {
    font-size: 24px;
    margin-bottom: 24px;
}

h3, .title__large {
    font-size: 18px;
    margin-bottom: 16px;
}

h4, .title__medium {
    font-size: 14px;
    margin-bottom: 16px;
}

@media (max-width: 1024px) {
    h2, .title__xlarge {
        font-size: 20px;
    }

    h3, .title__large {
        font-size: 16px;
    }
}
    */
@media (max-width: 600px) {
    .container {
        max-width: 100%;
    }
}

.row--spaced {
    margin-top: 64px;
    margin-bottom: 64px;

    @media (max-width: 1280px) {
        margin-top: 40px;
        margin-bottom: 40px;
    }
}

.divider-main {
    margin: 1px 0;
    border: 1px solid white;
    border-top: 0;
}

.sign-posting .flex-container {
    display: flex;
    margin-left: -12px;
    margin-right: -12px;
    justify-content: flex-start;

    @media (max-width: 1280px) {
        flex-wrap: wrap;
    }

    .flexbox {
        flex: 0 1 33.3333%;
        padding: 12px;

        @media (max-width: 1280px) {
            flex: 0 1 50%;
        }
    }

    .card-flat {
        padding: 24px;
        text-align: center;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        img {
            margin: 0 0 16px 0;
            max-width: 100%;

            + h3 {
                margin-top: 5px;
                font-size:18px;
            }
        }
    }

    @media (max-width: 600px) {
        flex-direction: column;
    }
}

#login-header {
    .ad-uniheader__container {
        justify-content: center;
    }

    .ad-uniheader__logo-container--link {
        padding: 0;
    }
}

#header {
    box-shadow: 0 1px 0 0 rgba(0,0,0,0.24);
}

#subnav-header,
.adb-secondary_nav {
    border-bottom: 0;
}

.profile_header .card__caption {
    color: inherit;

    a {
        color: $green-neutral;
    }

    span{
        margin-top:6px;
    }
}

.profile-actions .button {
    margin-left: 12px;
}

.profile-content {
    .stack:first-child,
    .stack:last-child {
        .stack--item:first-child,
        .stack--item:last-child {
            border: 0;
        }
    }

    div[class*="profile-feature"] {
        margin: 40px 0;
    }
}

.button__arrow {
    &_left:before {
        font-family: SageUIIcons;
        font-size: 16px;
        content: $sg-icon-caret-left;
    }

    &_right:after {
        font-family: SageUIIcons;
        font-size: 16px;
        content: $sg-icon-caret-right;
    }
}

.button {
    .icon__arrow_left::before {
        font-family: SageUIIcons;
        content: $sg-icon-caret-left;
        font-size: 16px;
    }

    .icon__arrow_right::before {
        font-family: SageUIIcons;
        content: $sg-icon-caret-right;
        font-size: 16px;
    }
}

.slider {
    &--item h3 a:hover {
        color: $green-neutral;
    }

    h3 {
        margin-bottom: 8px;
    }
}

.list-stack .list-stack .list-stack--item_content {
    padding: 12px 10px 12px 24px;
}

.profile-edition {
    border-radius: 24px;
    border: 1px solid $grey-border;

    &__header {
        padding: 16px;
        min-height: 54px;
        border-radius: 24px 24px 0 0;
    }

    &__content {
        padding: 16px;
    }

    &--primary {
        &::after {
            position: absolute;
            width: 48px;
            height: 75px;
            top: 0;
            right: 24px;
            content: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNDhweCIgaGVpZ2h0PSI3NXB4IiB2aWV3Qm94PSIwIDAgNDggNzUiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDU1LjIgKDc4MTgxKSAtIGh0dHBzOi8vc2tldGNoYXBwLmNvbSAtLT4KICAgIDx0aXRsZT5Db21iaW5lZCBTaGFwZTwvdGl0bGU+CiAgICA8ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz4KICAgIDxnIGlkPSJwYWdlcyIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGcgaWQ9Ii1hcHAtZGV0YWlscy1wYWdlLS0tcHJpY2luZyIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTg5MC4wMDAwMDAsIC01MzIuMDAwMDAwKSIgZmlsbD0iI0U5NjQwMCI+CiAgICAgICAgICAgIDxnIGlkPSJHcm91cC0xMiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoNjg0LjAwMDAwMCwgNTMyLjAwMDAwMCkiPgogICAgICAgICAgICAgICAgPGcgaWQ9Ikdyb3VwLTExIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxLjAwMDAwMCwgMC4wMDAwMDApIj4KICAgICAgICAgICAgICAgICAgICA8cGF0aCBkPSJNMjA1LDAgTDI1MywwIEwyNTMsNzUgTDIyOSw2MyBMMjA1LDc1IEwyMDUsMCBaIE0yMzcsMzIuMDAwMDE1NyBMMjMxLjU1NDk0OSwzMi4wMDAwMTU3IEwyMjguOTk5Mzg3LDI2IEwyMjYuNDQzODI0LDMyLjAwMDAxNTcgTDIyMSwzMi4wMDAwMTU3IEwyMjQuOTgwODU4LDM2LjQ0NDQ3MTggTDIyNCw0MiBMMjI4Ljk5OTM4NywzOS4wNTU1ODk4IEwyMzQsNDIgTDIzMy4wMTc5MTYsMzYuNDQ0NDcxOCBMMjM3LDMyLjAwMDAxNTcgWiIgaWQ9IkNvbWJpbmVkLVNoYXBlIj48L3BhdGg+CiAgICAgICAgICAgICAgICA8L2c+CiAgICAgICAgICAgIDwvZz4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==");
        }
    }
}

.profile-edition__header--primary {
    padding: 16px 76px 16px 16px;

    h3 {
        text-align: left;
    }
}

.profile-checklist__item .limit {
    margin-top: 8px;
}

.profile-edition {
    &__details {
        h4 {
            margin: 0;
            font: normal normal normal 12px/1.5 'Sage Text';
            color: $text-light;
        }
    }

    .button {
        margin-left: 0;
    }
}

.form--field > label {
    font-size: 16px;
    color: $text-color;
    font-weight:500;
}

/* fix for footer issue in management screens */
#footer ul {
    float: none;
}

.slat-container {
    border-radius: 24px;
    border: 1px solid $grey-border;

    .entry-title {
        margin-bottom: 8px;
    }

    .id__logo {
        width: 100px;
        height: 100px;
        border: none;
        font-size: 100px;
    }
}

.featured-reviews {
    margin-bottom: 64px;
}

.js-editions {
    margin-bottom: 64px;
}

.profile-edition__footnotes {
    background-color: transparent;
    padding: 0;
    margin-bottom: 64px;
}

.listing-page .listing-items {
    .container-box {
        border: 0;
        margin-bottom: 64px;
    }

    .container_header {
       // border: 1px solid $grey-border;
    }

    .container_footer {
        border: 0;
        background: transparent;
        padding: 0;
    }

    .listing-items-grid {
        margin: 0 -12px;
    }

    .grid-container {
        margin: 12px 0;
    }

    .tile-listing {
        margin: 12px;
        width: calc((100% - 72px)/3);
        padding: 16px;

        @media (max-width: 1280px) {
            width: calc((100% - 48px)/2);
        }

        @media (max-width: 600px) {
            width: calc(100% - 24px);
        }
    }

    .tile:nth-child(4n + 1) {
        padding-right: 16px;
    }

    .summary--title {
        margin-bottom: 4px;
    }

    .listing-row {
        margin-bottom: 24px;
        padding-bottom: 24px;
        border-bottom: 1px solid $grey-border;
        border-top: none;

        &:first-child {
            margin-top: 24px;
        }

        .compare-label {
            font-size: 12px;
            margin: 8px 0 0;

            .selector--value {
                margin-left: 18px;
            }

            .selector--input {
                width: 16px;
            }
        }
    }

    .listing-row-image {
        padding: 0 16px 0 0;
        width: 136px;

        @media (max-width: 1280px) {
            width: 90px;
        }
    }

    .listing-row-content {
        padding: 0 16px 0 0;

        h4 {
            font-family: 'Sage Text';
            line-height: 1.5;
        }
    }

    .listing-row-toolbar {
        padding: 0 0 0 0;
    }

    .listing-row-content-title {
        color: $text-color;
        margin-bottom: 8px;
        font-family: 'Sage Text', 'Helvetica Neue', Roboto, Arial, Sans-Serif;
        display: inline-block;

        + .js-badges-container {
            vertical-align: super;
        }
    }

    .listing-description .more {
        margin-left: 6px;
    }
}

.filters--filter {
    .dropdown {
        border-radius: 24px;
        color: $black;
        box-shadow: none;
        height: 33px;
        border: 2px solid black;
        background-color:transparent;

        .js-dropdown-placeholder {
            padding: 5px 8px 6px;
            font-weight:500;
        }

        &::before {
            padding: 7px 0 6px;
        }
    }

    .label__inline {
        margin-right: 8px;
        font-size: 16px;
        font-weight:500;
    }
}

.listing-filters {
    .adb-selector {
        margin: 8px 8px;
    }
    .selector--value{
        font-size:16px;
    }
    .form--field {
        margin-bottom: 24px;
        .listing-label {
            font-size:18px;
            margin-left:10px;
        }
    }
    
}

.myappsV2Page .adb-header {
    margin: 40px 0 0;

    &--item {
        margin: 0 0 16px;
        font-family: 'Sage Text', 'Helvetica Neue', Roboto, Arial, Sans-Serif;
    }
}

.myappsV2Page .adb-styled .adb-myapp--image {
    border-radius: 0;

    .adb-id--img {
        border-radius: 0;
    }
}

.tertiary-nav--mobile_menu .dropdown {
    border-radius: 0;
    color: $text-color;
    background: $grey-xl;
    height: 33px;

    &::before {
        padding: 6px 0;
    }
}

@media (max-width: 1260px) {
    .profile_header--title {
        font-size: 32px;
    }

    .profile-content .title__xxxxxlarge {
        font-size: 24px;
    }
}

@media (max-width: 768px) {
    .profile__image {
        margin-bottom: 64px;
    }
}

@media (max-width: 600px) {
    .profile_header--title {
        font-size: 24px;
    }

    .profile-content .title__xxxxxlarge {
        font-size: 21px;
    }

    .profile_header .card__image {
        margin-right: 16px;

        .rating__icon::before {
            font-size: 12px;
        }
    }
}


.secondary_nav .link-to-sage-com {
    float: right;
    margin-right: 0;
}

.modal .link-privacy {
    font-size: 14px;
    position: absolute;
    top: 50%;
    right: 140px;
}

.modal .link-cookies {
    font-size: 14px;
    position: absolute;
    top: 50%;
    right: 16px;
}

.home-faqs {
    .faq {
        margin-bottom: 24px;
    }

    h3 {
        margin-bottom: 8px;
    }

    p {
        margin: 0 0 16px;
    }
}

.form--field[data-field-required="true"] > label::before {
    content: "* "
}

.profile-edition__prices {
    height: auto !important;
}
