/// system base colors
$black: rgb(45,45,45) !default;             //#2d2d2d
$white: rgb(255,255,255) !default;          //#ffffff
$blue: rgb(0,154,191) !default;             //#009abf
$gray4: mix($white, $black, 90%) !default;  //#EAEAEA

/* dls override */
$black: #000000;
$text-color: rgba(0,0,0,.9);
$secondary-text-color: rgba(0,0,0,.65);
$blue: #0073c2;
$gray4: #ccd6db;

/* dls colors */
$green-neutral: #008146;
$slate: #003349;
$red: #CD384B;
$gold: #ffb500;
$carrot: #EF6700;
$grey-xl: rgba(0,0,0,.05);
$grey-l: rgba(0,0,0,.1);
$grey-m: rgba(0,0,0,.15);
$green-brilliant: #00D639;
$aegean: #0077cb;
$grey-border: #ccd6db;
$grey-icon: rgba(0,0,0,.74);
$text-light: rgba(0,0,0,.65);
$bg-slate-xl: #f2f5f6;
$green: $green-brilliant;

//Greys
$gray-s4: #1b1b1b;
$gray-s3: #303030;
$gray-s2: #474747;
$gray-s1: #5e5e5e;
$gray-t1: #777777;
$gray-t2: #919191;
$gray-t3: #ababab;
$gray-t4: #c6c6c6;
$gray-t5: #e2e2e2; //button, disabled
$gray-t6: #f0f0f0;